<template>
  <Button @click="onClick">
    <Icon :color="color" :name="milestones.length ? 'sign-direction' : 'plus'" size="xlg" />
  </Button>
  <CreateMilestoneModal
    v-if="isModalShown"
    :invention="invention"
    :milestone="{ createNew: true }"
    :creation-event="eventName"
    :model="{ next: docketingEvent }"
    :references="[`FIP:${docketingEvent.id}`]"
    @close="isModalShown = false"
    @created="isModalShown = false"
  />
</template>
<script>
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import CreateMilestoneModal from '@/components/milestones-overview/components/CreateMilestoneModal';
import { mapState } from 'vuex';

export default {
  components: { Icon, Button, CreateMilestoneModal },
  props: {
    milestones: {
      type: Array,
      default: () => []
    },
    docketingEvent: {
      type: Object,
      required: true
    },
    eventName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isModalShown: false
    };
  },
  computed: {
    ...mapState({
      invention: s => s.inventions.item
    }),
    color() {
      switch (this.eventName.split('.')[1]) {
        case 'created':
          return 'success';
        case 'deleted':
          return 'error';
        default:
          return '';
      }
    }
  },
  methods: {
    onClick() {
      if (this.milestones.length) {
        return;
      }
      this.isModalShown = true;
    }
  }
};
</script>
